import { Form, Button, Row, Col, Badge, Alert, Modal, Table, Spinner, CardColumns } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import {List, AutoSizer, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import FileBase64 from "react-file-base64";
import "./view_installation_as_admin.css"
import 'react-virtualized/styles.css'; // only needs to be imported once

export function ViewInstallationsAsAdmin() {

    let advances = {}
    let advances_inputs = {}
    let searchInput2 = "";
    const cache = new CellMeasurerCache({
        defaultHeight: 50,
        fixedWidth: true
      });
    const [data, setData] = useState([{}]);
    const [data2, setData2] = useState([]);
    const [data7, setData7] = useState(false);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [searchedView, setSearchedView] = useState(false);
    const [searchedData, setSearchedData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let date = '0000-00'

    const getData = async (withoutPayment) => {
        try {
            if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "sales") {
                const modalResponse = await axios.get(`https://tarnowokna.srv22208.microhost.com.pl/mount_notify_data.php?who=${Cookies.get("Nick")}`);
                //console.log(modalResponse);
                if (modalResponse.data !== "Nothing to show" && modalResponse.data.length !== 0) {
                    setModalData(modalResponse.data);
                    setShow(true);
                }
                if (withoutPayment) {
                    setIsLoading(true);
                    const response = await axios.get(`https://tarnowokna.srv22208.microhost.com.pl/get_data_for_branches.php?which=admin&not_paid=1`);
                    setData([{}]);
                    setData(response.data);
                    setData2(response.data);
                    //console.log(response.data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(true);
                    const response = await axios.get(`https://tarnowokna.srv22208.microhost.com.pl/get_data_for_branches.php?which=admin&not_paid=0`);
                    setData([{}]);
                    setData(response.data);
                    setData2(response.data);
                    //console.log(response.data)
                    setIsLoading(false);
                }
                //console.log(withoutPayment)
                setData7(withoutPayment)
            }
        } catch (e) {
            //console.log(e);
        }
    }

    useEffect(() => {
        getData(data7);
    }, [])

    const handleRead = async () => {
        const response = await axios.get(`https://tarnowokna.srv22208.microhost.com.pl/mount_notify_read.php?who=${Cookies.get("Nick")}`);
        setShow(false);
    }

    const fill_texts_dict = {

        "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PRODUCTS_NAME": { "label": "Sprzedany prod", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ORDER_NUMBER": { "label": "Nr zamówienia", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ADVANCE": { "label": "Zadatek", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
        "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
        "INVOICE_NUMBER": { "label": "Nr faktury", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ADDITIONAL_INFORMATIONS": { "label": "Dodatkowe info", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "COMPLAINT": { "label": "Reklamacja", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "WHO_ADDED": { "label": "Kto dodał", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        //        "CITY": { "label": "Miasto", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
        "ORDER_DATE": { "label": "Zamówione", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
        "CLEARED_DATE": { "label": "Montaż", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
    };
    const select_elements_dict = {
        "INVOICE_CREATED": { "label": "Faktura wypis.", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie", "Nie całkowicie"], "value": "" },
        "MATERIALS_AVALIABLE": { "label": "Materiały", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Nie zamówione", "Zamówione", "Na stanie"], "value": "" },
        "ORDER_CLEARED": { "label": "Zakończone", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "" },
    };

    function handleSaveEdited(installation_index) {
        let installation = data[installation_index]
        let installation_id = data[installation_index].ID
        if (installation.hasOwnProperty('ID'))
            delete installation['ID']
        axios.post(`https://tarnowokna.srv22208.microhost.com.pl/edit_installation.php?id=` + installation_id,
            installation,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(response => {
            if (response.status !== 200) {
                alert("Nie udało się zapisać!")
            }
            else if (response.status === 200) {
                alert("Zapisano!")
            }
        })
            .catch((error) => {
                if (error.status !== 200) {
                    alert("Nie udało się zapisać!")
                }
            })
    }

    function handleAddAdvance(installation_index) {
        let advance_to_add = advances[installation_index];
        //if (advance_to_add > 0){
        axios
            .post(
                `https://tarnowokna.srv22208.microhost.com.pl/add_advance.php`,
                {
                    order_id: data[installation_index].ID,
                    amount: advance_to_add
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then((response) => {
                getData(data7);
                if (response.status !== 200) {
                    alert("Nie udało się zapisać zaliczki!")
                }
                else if (response.status === 200) {
                    alert("Dodano zaliczkę!")
                }
            })
            .catch((error) => {
                if (error.status !== 200) {
                    alert("Nie udało się zapisać zaliczki!")
                }
            })
        //}
    }

    function handleDelete(installation_index) {
        let installation_id = data[installation_index].ID
        axios.get(`https://tarnowokna.srv22208.microhost.com.pl/delete_installation.php?id=` + installation_id).then(res => {
            getData(data7);
        })
    }

    function attachments(installation_index) {

        const File1 = (files) => {
            axios
                .post(
                    `https://tarnowokna.srv22208.microhost.com.pl/save_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name,
                        order: data[installation_index].ID,
                        attachment_nr: 1
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData(data7);
                })
        };
        const File2 = (files) => {
            axios
                .post(
                    `https://tarnowokna.srv22208.microhost.com.pl/save_attachment.php`,
                    {
                        file: files.base64,
                        filename: files.name,
                        order: data[installation_index].ID,
                        attachment_nr: 2
                    },
                    {
                        "Content-Type": "application/json",
                    }
                )
                .then((response) => {
                    getData(data7);
                })
        };

        function handleDownloadAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                if (data[installation_index].ATTACHMENT1 !== undefined) {
                    if (data[installation_index].ATTACHMENT1 !== '') {
                        window.open('https://tarnowokna.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENT1, "_blank");
                        return 0;
                    }
                }
            if (which_attachment === 2)
                if (data[installation_index].ATTACHMENT2 !== undefined) {
                    if (data[installation_index].ATTACHMENT2 !== '') {
                        window.open('https://tarnowokna.srv22208.microhost.com.pl/' + data[installation_index].ATTACHMENT2, "_blank");
                        return 0;
                    }
                }
        }

        function handleDeleteAttachment(installation_index, which_attachment) {
            if (which_attachment === 1)
                axios
                    .post(
                        `https://tarnowokna.srv22208.microhost.com.pl/delete_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENT1,
                            order: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData(data7);
                    })
            if (which_attachment === 2)
                axios
                    .post(
                        `https://tarnowokna.srv22208.microhost.com.pl/delete_attachment.php`,
                        {
                            filename: data[installation_index].ATTACHMENT2,
                            order: data[installation_index].ID,
                            attachment_nr: which_attachment
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    )
                    .then((response) => {
                        getData(data7);
                    })
        }

        let att1 = 'Załącznik 1'
        let att1bool = false
        let att2 = 'Załącznik 2'
        let att2bool = false
        if (data[installation_index].ATTACHMENT1 !== undefined) {
            if (data[installation_index].ATTACHMENT1 !== '' && data[installation_index].ATTACHMENT1 !== null) {
                att1 = data[installation_index].ATTACHMENT1.substring(14, 32)
                att1bool = true
            }
        }
        if (data[installation_index].ATTACHMENT2 !== undefined) {
            if (data[installation_index].ATTACHMENT2 !== '' && data[installation_index].ATTACHMENT2 !== null) {
                att2 = data[installation_index].ATTACHMENT2.substring(14, 32)
                att2bool = true
            }
        }
        return (
            <>
                <Badge className='m-1' variant="light">{'FAKTURA ->'}</Badge>
                {att1bool ? <div><Button className='ml-2' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 1)}>{att1}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 1)}>X</Button></div> :
                    <FileBase64 multiple={false} onDone={File1.bind()} />}
                <Badge className='m-1' variant="light">{'ZLECENIE ->'}</Badge>
                {att2bool ? <div><Button className='mx-0' variant="secondary" size='sm' onClick={() => handleDownloadAttachment(installation_index, 2)}>{att2}</Button> <Button className='ml-1 mr-3' variant="dark" size='sm' onClick={() => handleDeleteAttachment(installation_index, 2)}>X</Button></div> :
                    <FileBase64 multiple={false} onDone={File2.bind()} />}
            </>
        );
    }

    function FillTextElement(element_key, installation_index) {
        let control_by_type = <Form.Control className="m-0" as="textarea" rows={1} type="text" defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        if (fill_texts_dict[element_key]["data_type"] === "REAL") {
            control_by_type = <Form.Control className="m-0" type='number' step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (fill_texts_dict[element_key]["data_type"] === "DATE") {
            control_by_type = <Form.Control className="m-0" type='date' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (fill_texts_dict[element_key]["data_type"] === "REAL" && element_key === 'ADVANCE') {
            control_by_type = <Form.Control className="m-0" type='number' readOnly step="0.1" min='0' max='999999999' defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { data[installation_index][element_key] = e.target.value; }} />
        }
        if (element_key === 'WHO_ADDED') {
            control_by_type = <Form.Control className="m-0" readOnly as="textarea" rows={1} type="text" defaultValue={data[installation_index][element_key]} placeholder={fill_texts_dict[element_key]["placeholder"]} />
        }
        //        if (element_key === 'COMPLAINT' || element_key === 'WHO_ADDED') {
        //            return (
        //                <>
        //                    <Col className="m-0 col-3 col-md-3 col-lg-3">
        //                        <Form.Group className="m-0" controlId={element_key}>
        //                            <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
        //                            {control_by_type}
        //                        </Form.Group>
        //                    </Col>
        //                </>
        //            );
        //        }
        return (
            <>
                <Col className="m-0 col-6 col-md-4 col-lg-2">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0" size="sm">{fill_texts_dict[element_key]["label"]}</Form.Label>
                        {control_by_type}
                    </Form.Group>
                </Col>
            </>
        );
    }

    function SelectElement(element_key, installation_index) {
        return (
            <>
                <Col className="m-0 col-4 col-md-3 col-lg-2">
                    <Form.Group className="m-0" controlId={element_key}>
                        <Form.Label className="m-0">{select_elements_dict[element_key]["label"]}</Form.Label>
                        <Form.Control className="m-0" as="select"
                            onChange={(e) => {
                                let d = data;
                                d[installation_index][element_key] = e.target.value;
                                setData(d);
                            }}
                        >
                            {
                                select_elements_dict[element_key]["elements"].map((element) => {
                                    return <option selected={element === data[installation_index][element_key] ? true : false}>{element}</option>
                                }
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
            </>
        );
    }
    function columnedView(installation_index) {
        let elements = []
        let elements0_11 = []
        let elements12_23 = []
        for (var key in fill_texts_dict) {
            if (fill_texts_dict.hasOwnProperty(key)) {
                elements.push(FillTextElement(key, installation_index))
            }
        }

        for (var key in select_elements_dict) {
            if (select_elements_dict.hasOwnProperty(key)) {
                elements.push(SelectElement(key, installation_index))
            }
        }

        //        for (let step = 0; step < elements.length; step++) {
        //            if (step < 12)
        //                elements0_11.push(elements[step])
        //            else
        //                elements12_23.push(elements[step])
        //        }

        function color_by_status() {
            if (parseFloat(data[installation_index].ADVANCE) >= parseFloat(data[installation_index].PRICE) && data[installation_index].ORDER_CLEARED === "Tak")
                return { 'background-color': '#70ff96', border: '2px solid #000' }
            else if (parseFloat(data[installation_index].ADVANCE) >= parseFloat(data[installation_index].PRICE) && data[installation_index].ORDER_CLEARED !== "Tak")
                return { 'background-color': '#e9abff', border: '2px solid #000' }
            else if (parseFloat(data[installation_index].ADVANCE) <= parseFloat(data[installation_index].PRICE) && data[installation_index].ORDER_CLEARED === "Tak")
                return { 'background-color': '#fff696', border: '2px solid #000' }
            return { 'background-color': '#fff', border: '2px solid #000' }
        }

        return (
            <>
                <div className="py-2" style={color_by_status()}>
                    <div style={{ margin: '-2px 2px 0px 2px' }}>
                        <Form className="m-0">
                            <Row className="m-0">
                                <Badge className="mt-0 mr-1" variant="info" ><h5>{data[installation_index].ID}</h5></Badge>
                                <Badge className="mt-0" variant="info"><h5>{data[installation_index].ADDRESS} -- {data[installation_index].NAME}</h5></Badge>
                                <Button className='mx-1' variant="primary" size='sm' onClick={() => handleSaveEdited(installation_index)}>Zapisz</Button>
                                <Button className='mr-3' variant="danger" size='sm' onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć zamówienie ' + (installation_index + 1) + ' ?')) handleDelete(installation_index) }}>Usuń</Button>
                                {attachments(installation_index)}
                            </Row>
                            <Row className="m-0">
                                {elements.map((elem) => (
                                    elem
                                ))}
                                <Button className='mx-1 my-2' variant="info" size='sm' onClick={(e) => { e.preventDefault(); window.open("https://tarnowokna.srv22208.microhost.com.pl/row_to_pdf.php?id=" + data[installation_index].ID) }}>Drukuj</Button>
                                <Button className='mr-4 my-2' variant="info" size='sm' onClick={(e) => { e.preventDefault(); window.open("https://tarnowokna.srv22208.microhost.com.pl/advances_pdf.php?id=" + data[installation_index].ID) }}>Historia wpłat</Button>
                                <Form.Control className="mx-1 col-1 my-2" type='number' step="0.1" max='999999999' defaultValue={0} placeholder={"Zadatek"} onChange={(e) => { advances[installation_index] = e.target.value; advances_inputs[installation_index] = e }} />
                                <Button className='mx-1 my-2' variant="info" size='sm' onClick={() => { handleAddAdvance(installation_index); if (advances_inputs !== undefined && advances_inputs[installation_index] !== undefined) advances_inputs[installation_index].target.value = 0 }}>Dodaj wpłate</Button>
                            </Row>
                        </Form>
                    </div>
                </div>
            </>
        );

    }
    let shure_clients = 0;
    function installations() {
        let installations = []
        for (let i = 0; i < data.length; i++) {
            shure_clients++
            installations.push(columnedView(i))
        }
        return installations
    }
    let installations_arr = []
    if (Cookies.get("Rights") === "admin" || Cookies.get("Rights") === "sales") {
        installations_arr = installations()
    }

    function rowRenderer({
        key, // Unique key within array of rows
        index, // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible, // This row is visible within the List (eg it is not an overscanned row)
        style,
        parent // Style object to be applied to row (to position it)
      }) {
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
          <div key={key} style={style}>
                        { installations_arr[index] }
          </div>
          </CellMeasurer>
        );
      }

    const handleSearch = async () => {
        setIsLoading(true);
        
        const response = await axios.get(`https://tarnowokna.srv22208.microhost.com.pl/get_data_for_branches.php?which=admin&not_paid=0&search_for=${searchInput2}`);
            if(response.data.length !== 0){
                setData(response.data);
                setSearchedData(response.data);
                setIsLoading(false);
                document.getElementById("searchForm").value=searchInput2;
            }
        if(response.data.length === 0){
            alert("Brak wyników");
            setIsLoading(false);
            document.getElementById("searchForm").value=searchInput2;
        }
    }

    const changedSearchInput = (e) => {
        searchInput2 = e;
        if(e.length === 0){
            setData(data2);
        }
    }

    const clearInput = () => {
        setIsLoading(true);
        searchInput2 = "";
        setData(data2);
        document.getElementById("searchForm").value="";
        setIsLoading(false);
    }

    const renderModalTable= () => {
        return (
            <Table striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Imie i nazwisko</th>
                        <th>Numer telefonu</th>
                        <th>Adres</th>
                        <th>Sprzedany produkt</th>
                        <th>Numer zamówienia</th>
                        <th>Zadatek</th>
                        <th>Cena</th>
                        <th>Numer faktury</th>
                        <th>Dodatkowe info</th>
                        <th>Faktura wypisana</th>
                        <th>Materiały</th>
                        <th>Zakończone</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(modalData).map(e => {
                        console.log(e);
                        return(<tr>
                            <td>{e.ID}</td>
                            <td>{e.NAME}</td>
                            <td>{e.PHONE_NUMBER}</td>
                            <td>{e.ADDRESS}</td>
                            <td>{e.PRODUCTS_NAME}</td>
                            <td>{e.ORDER_NUMBER}</td>
                            <td>{e.ADVANCE}</td>
                            <td>{e.PRICE}</td>
                            <td>{e.INVOICE_NUMBER}</td>
                            <td>{e.ADDITIONAL_INFORMATIONS}</td>
                            <td>{e.INVOICE_CREATED}</td>
                            <td>{e.MATERIALS_AVALIABLE}</td>
                            <td>{e.ORDER_CLEARED}</td>
                        </tr>)
                    })}
                </tbody>
            </Table>
            )

    }

    return (
        <>
            <link
                rel="stylesheet"
                href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
                integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
                crossorigin="anonymous"
            />

            <div className="modal-90w">
                <Modal
                    dialogClassName="custom-modal"
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    backdrop="static"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Przypomnienie o montażu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {renderModalTable()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{flex: 1, alignItems: 'start'}} variant="primary" onClick={handleRead}>
                            Zaznacz jako odczytane
                        </Button>
                        <Button style={{flex: 1, alignItems: 'end'}} variant="secondary" onClick={handleClose}>
                            Zamknij
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            {isLoading ? <div class="spinner">
                            <Spinner style={{ width: "75px", height: "75px" }} animation="border" size="lg" variant="primary" />
                         </div>
            :            
            <div>
                <Form className="m-0">
                    <Row className="m-0">
                        <h2><Badge className='mx-1' variant="success">Ilość klientów {shure_clients}</Badge> </h2>
                        <h2><Button className='mx-1' variant="info" onClick={() => getData(!data7)}>Zakończone, nie zapłacone</Button></h2>
                        <h2 className="pl-5 mt-1"><Form.Control id="searchForm" defaultValue={""} placeholder={"Wyszukaj"} onInput={(e) => { changedSearchInput(e.target.value)}} /></h2>
                        <h2 className='pl-1 mx-1'>
                            <Button onClick={() => clearInput()} variant="danger">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                                </svg>
                            </Button>
                        </h2>
                        <h2 className='mx-1 ml-5'><Button onClick={() => handleSearch()} style={{width: '100px'}}>Szukaj</Button></h2>

                    </Row>
                </Form>
                <div style={{width: "100%", height: "89vh"}}>

           
<AutoSizer >
    {({height, width}) => (
<List
    width={width}
    height={height}
    rowCount={data.length}
    rowHeight={cache.rowHeight}
    deferredMeasurementCache={cache}
    rowRenderer={rowRenderer}
    />
    )}
</AutoSizer>
</div>
{/* {installations_arr.map((elem) => (
    elem
))} */}

</div>}
        </>
    );
}

export default withRouter(ViewInstallationsAsAdmin);
